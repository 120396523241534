<!--hướng dẫn
import CusttomDate from '@/views/management-business/business/pages/components/CusttomDate.vue'
 <custtom-date
            :valueDate="valueData.dateIssuedString"
            :validee="false"
            :label="'Ngày cấp'"
            @valueDate="e=>valueData.dateIssuedString = e"
          />
-->
<template>
  <b-form-group
    label-for="basicInput"
  >
    <label
      for="basicInput"
    >{{ label }}<span
      v-if="validee"
      class="required"
    >(*)</span></label>
    <validation-provider
      v-if="validee"
      #default="{ errors }"
      rules="required"
      :custom-messages="customVali"
    >
      <date-time-picker
        v-model="valueDate"
        :disabledInput="true"
        @input="data01"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <date-time-picker
      v-else
      v-model="valueDate"
      :disabledInput="true"
      @input="data01"
    />
  </b-form-group>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    DateTimePicker,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    label: {
      default: 'nhập props :label',
    },
    // eslint-disable-next-line vue/require-prop-types
    validee: {
      default: 'true',
    },
    // eslint-disable-next-line vue/require-prop-types
    valueDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customVali: {
        required: `${this.label} là bắt buộc`,
      },
      required,
    }
  },
  methods: {
    data01(e) {
      this.$emit('valueDate', this.valueDate)
    },
  },

}
</script>

<style>

</style>
