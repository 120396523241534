/* eslint-disable no-bitwise */
<template>
  <div class="infomation-and-adress">
    <h3 class="d-flex align-items-center mb-2">
      <feather-icon
        size="20"
        icon="ImageIcon"
        class="mr-75"
      />
      <span>
        Thiết lập biểu ngữ
      </span>
    </h3>
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--=============== Biểu trưng=================-->
        <b-col
          md="12"
          xl="12"
        >
          <CusttomAvatar
            id="BIA"
            idFake="idBIA"
            idInput="BIAInput"
            :urlImg="dataInput"
            label="Ảnh minh họa"
            class="image-home-page"
            @url="imgBIA($event)"
          />
        </b-col>
      </b-row>
    </div>

    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Ngôn ngữ  =================-->
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label-for="name"
          >
            <label for="name">Tiêu đề biểu ngữ</label>
            <b-form-input
              v-model="dataInput.title"
            />
          </b-form-group>
        </b-col>
        <!--===============  Múi giờ  =================-->
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label-for="name"
          >
            <label for="name">Phụ đề biểu ngữ</label>
            <b-form-input
              v-model="dataInput.titleContent"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BFormTextarea, BDropdown, BMedia,
  BAvatar,
  BFormGroup,
  BFormInput,
  BDropdownItem,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import CusttomTitle from '@/views/management-business/business/pages/components/InformationAndAdressTitle.vue'
import CusttomInput from '@/views/management-business/business/pages/components/CusttomInput.vue'
import CusttomAvatar from '@/views/settings/setting/pages/components/CusttomAvatar.vue'
import CusttomSelect from '@/views/management-business/business/pages/components/CusttomSelect.vue'
import CusttomDate from '@/views/management-business/business/pages/components/CusttomDate.vue'

export default {
  components: {
    CusttomTitle,
    CusttomInput,
    BRow,
    BCol,
    CusttomSelect,
    CusttomDate,
    BFormCheckbox,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    CusttomAvatar,
    BFormGroup,
    BFormInput,
  },

  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
  computed: {
  },
  methods: {
    imgBIA(val) {
      this.dataInput.banner = val
    },
  },
}
</script>

<style lang="scss">
.switch{
display: flex;
align-items: center;
justify-content: space-between;
}

#idBIA{
  .remove-file {
    position: absolute;
    margin-top: 85px;
    margin-left: -310px;
    width: 40px;
    height: 40px;
  }
  .image-preview{
    width: 530px !important;
    height: 240px !important;
  }
  @media (max-width: 650px) {
    .image-preview{
    width: 330px !important;
    height: 180px !important;
    }
    .remove-file{
      margin-left: -209px;
      margin-top: 46px;
    }
  }
}

.image-home-page {
  .image-preview {
    img {
      background: #EFEFEF !important;
      object-fit: contain !important;
    }
  }
}
</style>
